import api from 'configs/api'
import moment from 'moment'

const dateFormated = (value: Date) => {
  const dayInit = moment(value).format('01/MM/YYYY')
  const lastDay = moment.utc(value).daysInMonth()
  const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
  return `start=${dayInit}&end=${dayEnd}`
}

export const getCsat = async (
  id: string,
  value: Date,
  setCountCsat: any,
  setRatings: any,
  setComents: any,
  setLoading: any
) => {
  setLoading(true)
  try {
    const { data } = await api.get(
      `manager/reports/rating/doctor/${id}?${dateFormated(value)}`
    )

    const good = Number(data?.verySatisfied) + Number(data.satisfied)
    const bad =
      Number(data?.indifferent) +
      Number(data.veryDissatisfied) +
      Number(data.dissatisfied)

    const total = good + bad
    const count = good / total
    const countRotate = good ? (count * 100).toFixed(0) : 0
    setCountCsat(Number(countRotate))

    setRatings(data.emotions)
    setComents(data.coments)
    setLoading(false)
  } catch (e) {
    console.error(e)
    setLoading(false)
  }
}
