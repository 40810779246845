import React, { useState } from 'react'
import moment from 'moment'
import { orderBy } from 'lodash'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { Popover, OverlayTrigger, Form } from 'react-bootstrap'
import { translate } from 'locales'

// Components
import { Button } from 'components'

// Styles
import * as S from './styles'
import 'bootstrap/dist/css/bootstrap.min.css'

// Utils
import { getTimes } from './utils'

// Services
import {
  handleSaveScheduleConfiguration,
  handleDeleteSchedule,
} from './services'

const OverlayConfig = ({
  info,
  occupations,
  selectedOccupation,
  setSelectedOccupation,
  country,
  groups,
  selectedCountry,
  setSelectedCountry,
  handleOccupationSelection,
  handleCountrySelection,
  newSchedule,
  setNewSchedule,
  doctorSchedule,
  setDoctorSchedule,
  setLoading,
}: any) => {
  const day = moment(info?.event.startStr).format('YYYY-MM-DD')
  const [repeatSchedule, setRepeatSchedule] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState<any>('Padrão')
  const locales = window.localStorage.getItem('@StarBem:lang')

  const locale = locales === 'en' ? 'en-us' : 'pt-br'
  const interval =
    selectedOccupation.slug === 'clinico-geral'
      ? 60
      : selectedOccupation?.attendance_time || 30

  return (
    <OverlayTrigger
      trigger="click"
      placement="auto-start"
      defaultShow={info?.event?.extendedProps.status === 'pendent'}
      rootClose
      overlay={
        info?.event?.extendedProps.status === 'pendent' ? (
          <S.PopverContainer>
            <Popover.Title as="h3">
              {`${moment
                .utc(info?.event.startStr)
                .locale(locale)
                .format('dddd, DD/MM')} - ${translate('schedule.of')} ${moment
                .utc(info?.event.startStr)
                .locale(locale)
                .format('HH:mm')} ${translate('schedule.at')} ${moment
                .utc(info?.event.endStr)
                .locale(locale)
                .format('HH:mm')}`}
            </Popover.Title>
            <Popover.Content>
              <h4>{translate('schedule.initAt')}</h4>
              <Form.Control
                as="input"
                value={moment
                  .utc(info?.event.startStr)
                  .locale('pt-br')
                  .format('HH:mm')}
                custom
                disabled
              />

              <h4>{translate('schedule.endAt')}</h4>
              <Form.Control
                as="select"
                value={newSchedule.end}
                custom
                onChange={(e) =>
                  setNewSchedule({ ...newSchedule, end: e.target.value })
                }
              >
                {getTimes(
                  moment
                    .utc(info?.event.startStr)
                    .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
                  interval
                ).map((time) => (
                  <option key={time} value={`${day}T${time}:00Z`}>
                    {time}
                  </option>
                ))}
              </Form.Control>

              <h4>{translate('schedule.occupationArea')}</h4>

              {occupations && (
                <Form.Control
                  as="select"
                  value={selectedOccupation.id}
                  custom
                  onChange={(e) => handleOccupationSelection(e)}
                >
                  <option disabled>Selecionar</option>
                  {orderBy(occupations, ['areaName'], ['asc']).map(
                    (area: any) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    )
                  )}
                </Form.Control>
              )}
              <div className="form-checkbox" key="scheduleRepetition">
                <label htmlFor="scheduleRepetition" className="group-checkbox">
                  <input
                    id="scheduleRepetition"
                    checked={repeatSchedule}
                    type="checkbox"
                    onChange={() => setRepeatSchedule(!repeatSchedule)}
                  />
                  {translate('schedule.recurrence')}
                </label>
              </div>

              {['nutricionista', 'psicologo', 'psiquiatra'].includes(
                selectedOccupation.slug
              ) && (
                <>
                  <h4>{translate('schedule.country')}</h4>
                  {country && (
                    <Form>
                      {country.includes('BR') && (
                        <Form.Check
                          inline
                          label={translate('schedule.portuguese')}
                          checked={selectedCountry.includes('BR')}
                          onChange={handleCountrySelection}
                          name="country"
                          type="checkbox"
                          id="BR"
                        />
                      )}
                      {country.includes('US') && (
                        <Form.Check
                          inline
                          label={translate('schedule.english')}
                          checked={selectedCountry.includes('US')}
                          onChange={handleCountrySelection}
                          name="country"
                          type="checkbox"
                          id="US"
                        />
                      )}
                    </Form>
                  )}
                </>
              )}

              {selectedOccupation.slug === 'psicologo' &&
                groups &&
                groups?.length > 0 && (
                  <>
                    <h4>{translate('schedule.attendanceFormat')}</h4>
                    <Form>
                      <Form.Check
                        key={'Padrão'}
                        inline
                        label={'Padrão'}
                        checked={selectedGroups === 'Padrão'}
                        onChange={() => setSelectedGroups('Padrão')}
                        name="planGroup"
                        type="radio"
                        id={'Padrão'}
                      />
                      {groups?.map((item: any) => (
                        <Form.Check
                          key={item}
                          inline
                          label={item}
                          checked={selectedGroups === item}
                          onChange={() => setSelectedGroups(item)}
                          name="planGroup"
                          type="radio"
                          id={item}
                        />
                      ))}
                    </Form>
                  </>
                )}

              <Button
                type="button"
                color="primary"
                size="small"
                className="buttonHighlight"
                onClick={() =>
                  handleSaveScheduleConfiguration(
                    occupations,
                    selectedOccupation,
                    setSelectedOccupation,
                    country,
                    selectedCountry,
                    setSelectedCountry,
                    newSchedule,
                    setNewSchedule,
                    doctorSchedule,
                    setDoctorSchedule,
                    setLoading,
                    repeatSchedule,
                    selectedGroups
                  )
                }
              >
                {translate('schedule.save')}
              </Button>

              <div className="button-cancel-schedule">
                <button type="submit" onClick={() => setNewSchedule({})}>
                  {translate('schedule.cancel')}
                </button>
              </div>
            </Popover.Content>
          </S.PopverContainer>
        ) : (
          <S.PopverContainer>
            <Popover.Title as="h3">
              {translate('schedule.appointment')}
            </Popover.Title>
            <Popover.Content>
              <h4>
                <h4>
                  {moment
                    .utc(info.event.startStr)
                    .locale('pt-br')
                    .format('dddd, DD/MM')}
                </h4>
                <span
                  style={{
                    display: 'block',
                    marginTop: 12,
                    fontWeight: 'normal',
                  }}
                >
                  {`Das ${moment
                    .utc(info.event.startStr)
                    .locale('pt-br')
                    .format('HH:mm')} às ${moment
                    .utc(info.event.endStr)
                    .locale('pt-br')
                    .format('HH:mm')}`}
                </span>
              </h4>

              <Button
                type="button"
                color="danger"
                size="small"
                className="buttonHighlight"
                onClick={() =>
                  handleDeleteSchedule(
                    info?.event.id,
                    doctorSchedule,
                    setDoctorSchedule,
                    setLoading
                  )
                }
              >
                {translate('schedule.remove')}
              </Button>
            </Popover.Content>
          </S.PopverContainer>
        )
      }
    >
      <S.EventContent>
        <p>
          {info?.event && info.event.extendedProps.status === 'confirmed' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <div
                style={{ flex: 1, display: 'flex' }}
                title={info?.event?.extendedProps.occupation.name}
              >
                {String(info?.event?.extendedProps.occupation.name).slice(
                  0,
                  10
                )}
                ...
              </div>
              <DeleteOutlineIcon style={{ fontSize: 20 }} />
            </div>
          ) : (
            translate('schedule.notTitle')
          )}
        </p>
      </S.EventContent>
    </OverlayTrigger>
  )
}

export default OverlayConfig
