import React, { useEffect } from 'react'
import { TextField as InputField } from '@material-ui/core'
import moment from 'moment'

import { translate } from 'locales'

// Styles
import { Title, TextArea } from 'components'
import useStorage from 'hooks/storageSync'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

// Hooks

const Anamnese: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
  scheduleData,
}) => {
  const [storage, setStorage, setAllStorage]: any = useStorage(
    scheduleUUID,
    'anamnese'
  )

  // Recupera os valores salvos no storage para a consulta atual na sessão Conduct
  const dataStorage: any = storage?.anamnese

  const handleChange = (key: string, e: string) => {
    setStorage(key, e)
  }
  const { patient } = scheduleData

  /* eslint-disable */
  useEffect(() => {
    if (patient) {
      const data = {
        ...storage,
        ['anamnese']: {
          ...storage?.['anamnese'],
        },
      }

      setAllStorage(data)
    }
  }, [scheduleData])
  /* eslint-enable */

  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>
      <div className="subsection">
        <Title text="Anamnese" />
        <div className="subsection-item">
          <span>{translate('medicalRecord.reason')}</span>
          <h5 style={{ marginTop: '10px', fontSize: '14px' }}>
            {!scheduleData?.symptoms
              ? translate('medicalRecord.uninformed')
              : scheduleData?.symptoms}
          </h5>
        </div>

        <div className="subsection-item">
          <span>{`${translate('medicalRecord.compaint')} *`}</span>
          <TextArea
            value={dataStorage?.complaint}
            name="complaint"
            rows={5}
            wrap="hard"
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>

        <div className="subsection-item">
          <span>{`${translate('medicalRecord.historyCurrent')} *`}</span>
          <TextArea
            value={dataStorage?.history}
            name="history"
            rows={5}
            wrap="hard"
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>

        <div className="subsection-item">
          <span>{translate('medicalRecord.allergies')}</span>
          {patient?.medical_record?.medical_allergy_description && (
            <>
              <br />
              <h5>
                {`${translate('medicalRecord.informedByPatient')} ${
                  patient?.medical_record?.medical_allergy_description
                }`}
              </h5>
            </>
          )}
          <InputField
            color="secondary"
            variant="outlined"
            className="input-default"
            name="allergies"
            type="text"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={dataStorage?.allergies}
            label={false}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>

        {/* <div className="subsection-item">
          <span>Diabetes:</span>
          <RadioGroup
            aria-label="diabete"
            name="diabetes"
            style={{ display: 'flex', flexDirection: 'row' }}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          >
            <FormControlLabel
              value="sim"
              checked={dataStorage?.diabetes === 'sim'}
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              value="nao"
              checked={dataStorage?.diabetes === 'nao'}
              control={<Radio />}
              label="Não"
            />
          </RadioGroup>
        </div> */}

        <div className="subsection-item">
          <span>{translate('medicalRecord.pathologicalBackground')}</span>
          {patient?.medical_record?.Medical_record_heath_problem?.length !==
            0 && (
            <>
              <br />
              <h5>
                {`${translate(
                  'medicalRecord.informedByPatient'
                )} ${patient?.medical_record?.Medical_record_heath_problem.map(
                  (pat: any) => ` ${pat.name}`
                ).toString()}`}
              </h5>
            </>
          )}

          <TextArea
            value={dataStorage?.pathologicalBackground}
            name="pathologicalBackground"
            rows={3}
            wrap="hard"
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>

        <div className="subsection-item">
          <span>{translate('medicalRecord.continuousRemedy')}</span>
          {patient?.medical_record?.continuous_remedy && (
            <>
              <br />
              <h5>
                {`${translate('medicalRecord.informedByPatient')} ${
                  patient?.medical_record?.continuous_remedy
                }`}
              </h5>
            </>
          )}
          <TextArea
            value={dataStorage?.continuousRemedy}
            name="continuousRemedy"
            rows={3}
            wrap="hard"
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>
      </div>
    </S.SectionContent>
  )
}

export default Anamnese
