import React, { useMemo, useEffect, useState } from 'react'
import { telephoneMask, telephoneUnmask } from 'js-essentials-functions'
import { Visibility, VisibilityOff } from '@material-ui/icons'

// Context
import { useUser } from 'context/user/user.context'

// Components
import { Input, Button } from 'components'

import { translate } from 'locales/index'

// Styles
// import specialtiesList from 'utils/specialties'
import * as S from './styles'

// Services
import { updateUserData } from './services'

// Types
import { IForm } from './interfaces'

const FormProfile: React.FunctionComponent<IForm> = ({ setLoading }) => {
  const { userData, setUserData } = useUser()

  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
    useState(false)

  const passwordLength = useMemo(() => {
    return newPassword ? newPassword.length >= 8 : false
  }, [newPassword])

  const passwordHasNumber = useMemo(() => {
    const regex = /\d/
    return regex.test(newPassword || '')
  }, [newPassword])

  const passwordHasUpperCaseLetter = useMemo(() => {
    const regex = /[A-Z]/
    return regex.test(newPassword || '')
  }, [newPassword])

  const passwordHasLowerCaseLetter = useMemo(() => {
    const regex = /[a-z]/
    return regex.test(newPassword || '')
  }, [newPassword])

  useEffect(() => {
    const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/

    const passwordMatch = newPassword === newPasswordConfirmation
    const passwordIsValid =
      passwordMatch && regexPassword.test(newPassword || '')

    if (!newPassword || passwordIsValid) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [newPassword, newPasswordConfirmation])

  return (
    <S.FormContent>
      <h3>{translate('profile.personalData')}</h3>
      <div className="content-input">
        <Input
          name="nome"
          placeholder={translate('profile.name')}
          value={userData.name}
          type="text"
          onChange={() => {}}
          disabled
        />
      </div>
      <div className="content-input">
        <Input
          name="email"
          placeholder={translate('profile.email')}
          value={userData.email}
          type="email"
          disabled
          onChange={() => {}}
        />
      </div>
      <div className="content-input">
        <Input
          name="celular"
          placeholder={translate('profile.cellphone')}
          maxLength={15}
          value={
            userData?.profile?.cellphone && userData?.profile?.cellphone !== '-'
              ? telephoneMask(userData.profile.cellphone)
              : ''
          }
          type="text"
          onChange={(e) => {
            setUserData({
              ...userData,
              profile: {
                ...userData.profile,
                cellphone: telephoneUnmask(e.currentTarget.value),
              },
            })
          }}
        />
      </div>
      <div className="content-inputs">
        <div className="content-input">
          <Input
            name="crm"
            placeholder={`Número do ${userData?.profile?.professional_document_type}`}
            value={userData?.profile?.professional_document_number}
            type="text"
            onChange={() => {}}
            disabled
          />
        </div>
        <div className="content-input">
          <Input
            name="estado"
            placeholder={translate('profile.state')}
            value={userData?.profile?.professional_document_uf}
            type="text"
            onChange={() => {}}
            disabled
          />
        </div>
      </div>

      <div
        className="content-input"
        style={{ marginTop: 32, position: 'relative' }}
      >
        <Input
          name="novaSenha"
          placeholder={translate('profile.newPassword')}
          maxLength={15}
          value={newPassword || ''}
          type={showNewPassword ? 'text' : 'password'}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <button
          className="button-show-password"
          type="button"
          onClick={() => setShowNewPassword(!showNewPassword)}
        >
          {showNewPassword ? <VisibilityOff /> : <Visibility />}
        </button>
      </div>
      <div className="content-input" style={{ position: 'relative' }}>
        <Input
          name="confirmarNovaSenha"
          placeholder={translate('profile.confirmNewPassword')}
          maxLength={15}
          value={newPasswordConfirmation || ''}
          type={showNewPasswordConfirmation ? 'text' : 'password'}
          onChange={(e) => setNewPasswordConfirmation(e.target.value)}
          disabled={!newPassword}
        />
        <button
          className="button-show-password"
          type="button"
          onClick={() =>
            setShowNewPasswordConfirmation(!showNewPasswordConfirmation)
          }
        >
          {showNewPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
        </button>
      </div>

      {newPassword && (
        <div className="new-password-demands">
          <hr />
          <p>{translate('profile.passwordRules.one')}</p>
          <ul>
            <li style={{ color: passwordLength ? 'green' : 'red' }}>
              {translate('profile.passwordRules.two')}
            </li>
            <li style={{ color: passwordHasUpperCaseLetter ? 'green' : 'red' }}>
              {translate('profile.passwordRules.three')}
            </li>
            <li
              style={{
                color: passwordHasLowerCaseLetter ? 'green' : 'red',
              }}
            >
              {translate('profile.passwordRules.four')}
            </li>
            <li
              style={{
                color: passwordHasNumber ? 'green' : 'red',
              }}
            >
              {translate('profile.passwordRules.five')}
            </li>
          </ul>
        </div>
      )}

      <div className="area-button">
        <Button
          type="button"
          color="primary"
          size="small"
          onClick={() =>
            updateUserData(
              { ...userData, password: newPassword },
              setLoading,
              setNewPassword,
              setNewPasswordConfirmation
            )
          }
          disabled={disableButton}
        >
          {translate('profile.save')}
        </Button>
      </div>
    </S.FormContent>
  )
}

export default FormProfile
