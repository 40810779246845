import axios from 'axios'
import { alertDanger } from 'utils/toast'

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVICES_URL,
  timeout: 30000,
})

api.interceptors.request.use(
  async (config) => {
    const token = window.localStorage.getItem('token')

    const locales = window.localStorage.getItem('@StarBem:lang')
    const valueLocale = locales === 'pt' || !locales ? 'pt-BR' : 'en-US'

    config.headers.Authorization = token
      ? `Bearer ${token}`
      : `Basic ${process.env.REACT_APP_API_KEY}`

    config.headers['Accept-Language'] = valueLocale

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { status } = error.response

    if (status === 403 || status === 401) {
      window.localStorage.clear()
      alertDanger('Sua sessão expirou! Faça login novamente.')

      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }

    return Promise.reject(error)
  }
)

export default api
