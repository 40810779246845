import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import { translate } from 'locales'

// Styles
import { Title, Loading, CardDate } from 'components'
import noData from 'assets/images/no-data.svg'
import * as S from './styles'

import TimeLineFollowUp from './TimeLineFollowUp'

// Interfaces
import { IComponentProps } from './interfaces'

import { findAllMedicalRecordsFollowUp } from '../Closed/services'

moment.locale('pt-br', {
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
})

const useStyles = makeStyles((theme: any) => ({
  timeline: {
    padding: 0,
  },
  left: {
    flex: 'none',
    paddingLeft: 0,
  },
  right: {
    paddingRight: 0,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const FollowUp: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleData,
}) => {
  const classes = useStyles()

  // States
  const [timeline, setTimeline] = useState<any>({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    findAllMedicalRecordsFollowUp(
      scheduleData?.patient?.id,
      setTimeline,
      setLoading
    )
    // eslint-disable-next-line
  }, [scheduleData])

  return (
    <S.SectionContent>
      {loading && (
        <Loading title={translate('medicalRecord.loadingAcompaniments')} />
      )}

      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <div className="subsection">
        <Title text={translate('medicalRecord.historicAcompaniments')} />
        <div className="subsection-item">
          <Timeline align="left" className={classes.timeline}>
            {timeline && Object.keys(timeline).length === 0 && (
              <S.NoData>
                <img
                  src={noData}
                  alt="Icone simbolizando que não existe acompanhamento do paciente"
                />
                <span>{translate('medicalRecord.notAccompaniment')}</span>
              </S.NoData>
            )}

            {timeline.medicalRecordsFollowUp &&
              Object.keys(timeline.medicalRecordsFollowUp).map(
                (record: any) => (
                  <TimelineItem key={record}>
                    <TimelineOppositeContent className={classes.left}>
                      <CardDate
                        day={moment.utc(record).format('DD')}
                        month={moment.utc(record).format('MMM')}
                        year={moment.utc(record).format('YYYY')}
                      />
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={classes.right}>
                      {timeline.medicalRecordsFollowUp[record].map((i: any) => (
                        <Accordion key={i.id}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id={i.id}
                          >
                            <Typography className={classes.heading}>
                              {`${translate('medicalRecord.by')} ${
                                i?.nursing?.name
                              }`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails style={{ display: 'block' }}>
                            <TimeLineFollowUp
                              record={i}
                              doctorName=""
                              scheduleUUID=""
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </TimelineContent>
                  </TimelineItem>
                )
              )}
          </Timeline>
        </div>
      </div>
    </S.SectionContent>
  )
}

export default FollowUp
