import React from 'react'
import './styles.scss'

interface InputProps {
  placeholder?: string
  // react/default-props-match-prop-types
  name: string
  value?: string | number
  // react/default-props-match-prop-types
  type: 'text' | 'email' | 'number' | 'password' | 'date' | 'time' | undefined
  label?: boolean
  maxLength?: number
  disabled?: boolean
  required?: boolean

  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  min?: string
  styles?: any
}

const Input: React.FunctionComponent<InputProps> = ({
  placeholder,
  name,
  value,
  maxLength,
  label,
  type,
  disabled,
  onChange,
  required,
  min,
  styles,
}) => {
  return (
    <div
      id="input-container"
      style={{
        backgroundColor: disabled ? '#f4f4f4' : 'transparent',
        cursor: disabled ? 'no-drop' : 'auto',
        ...styles,
      }}
    >
      <input
        className={label ? 'input' : 'input-no-padd'}
        placeholder={placeholder}
        name={name}
        value={value}
        maxLength={maxLength}
        type={type}
        disabled={disabled}
        onChange={onChange}
        required={required}
        id={name}
        min={min}
      />
      {label && (
        <label
          htmlFor={name}
          className="label"
          style={{
            cursor: disabled ? 'no-drop' : 'auto',
          }}
        >
          {placeholder}
        </label>
      )}
    </div>
  )
}

// Input.defaultProps = defaultProps

export default Input
