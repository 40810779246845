import React from 'react'
import api from 'configs/api'
import { alertDanger, alertSuccess } from 'utils/toast'
import { translate } from 'locales'
import { IMedicalRecord } from './interfaces'

export const saveMedicalRecord = async (
  userUUID: string,
  scheduleUUID: string,
  data: IMedicalRecord,
  history: any,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true)

  if (data?.analysis && data?.analysis.length > 20000) {
    setLoading(false)
    return alertDanger(translate('errors.textMax'))
  }
  try {
    const save = await api.post('/doctors/v2/medical-record/psicology', {
      patient_id: userUUID,
      scheduling_id: scheduleUUID,
      specialty_id: data.specialty,
      annotation: data.analysis,
      tags_doctor: data?.tagsDoctor,
      tags_patient: data?.tagsPatient,
      nutritional_anamnese: data?.nutritional_anamnese,
    })
    if (save.data) {
      await api.put(`/schedulings/v2/${scheduleUUID}`, { status: 'finished' })
      setLoading(false)
      history.replace('/dashboard')
      window.localStorage.removeItem(`starbem:schedule:${scheduleUUID}`)
      window.localStorage.removeItem(`starbem:scheduling:${scheduleUUID}`)
      window.localStorage.removeItem(`clickTime${scheduleUUID}`)
      return alertSuccess(translate('success.schedulingFinished'))
    }
    setLoading(false)
    return alertDanger(
      save.data?.data?.errors[0].msg || translate('errors.errorTry')
    )
  } catch (err: any) {
    setLoading(false)
    return alertDanger(
      err?.response?.data?.data?.errors[0]?.msg ||
        translate('errors.internalError')
    )
  }
}

export const findAllUserPsicologyMedicalRecord = async (
  patient: any,
  specialty: string,
  isActive: boolean,
  setTimeline: React.Dispatch<React.SetStateAction<Array<any>>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  page?: number,
  setTotalPages?: any
) => {
  try {
    const { data } = await api.get(
      `/doctors/v2/medical-record/user/${patient.id}/psico/${specialty}?page=${page}&limit=6`
    )

    setTotalPages(data?.totalPages)
    setTimeline(data.medicalRecords)
    setLoading(false)
  } catch (err: any) {
    alertDanger(
      err?.response?.data?.data?.errors[0]?.msg ||
        translate('errors.internalError')
    )
    setLoading(false)
  }
}

export const saveNutritionalGuidance = async (
  scheduleUUID: string,
  patientId: string,
  nutritionalGuidance: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true)
    await api.post(`doctors/v1/nutritional-guidance`, {
      appointment_id: scheduleUUID,
      patient_id: patientId,
      nutritional_guidance: nutritionalGuidance,
    })
    setLoading(false)
    return alertSuccess(translate('success.orientationSave'))
  } catch (e: any) {
    setLoading(false)
    return alertDanger(
      e?.response?.data.message || translate('errors.errorOrientationSave')
    )
  }
}

export const createAccompaniment = async (data: any) => {
  try {
    const response = await api.post('/doctors/v1/accompaniment', data)
    return response.data
  } catch (err: any) {
    return alertDanger(
      err.response?.data?.data?.message || translate('errors.internalError')
    )
  }
}
