import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { ITheme } from 'theme/interface'

interface IMenuList {
  className?: string
  theme: ITheme
}

export const GridContent = styled(Grid)`
  width: 100%;
  max-width: 1280px !important;
  height: 100%;
  padding: 90px 20px 20px;
  position: relative;
  margin: 0 auto !important;
`

export const TopContent = styled.div`
  width: 100%;
  padding: 20px 20px 0 20px;

  h3 {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.gray80};
  }
`

export const Menu = styled.nav`
  margin-top: 20px;

  ul {
    width: 100%;

    li {
      width: 100% !important;
      list-style: none;

      button {
        width: 100%;
        background-color: transparent;
        height: 40px;
        border: none;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.secondary};
        padding-left: 20px;
        outline: none !important;

        :disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
    }
  }
`

export const MenuList = styled.li<IMenuList>`
  width: 100%;
  background-color: ${({ className, theme }) =>
    className === 'active' ? theme.colors.background : 'transparent'};
  border-left: ${({ className, theme }) =>
    className === 'active'
      ? `2px solid ${theme.colors.secondary}`
      : 'none'} !important;

  border-top: 1px solid
    ${({ className, theme }) =>
      className === 'active' ? 'transparent' : theme.colors.gray10} !important;
  height: 40px;
  border: none;
  text-align: left;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
`

export const PatientContent = styled.div`
  .patient-data {
    width: 100%;
    display: flex;
    flex-direction: row;

    .cont-left {
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  h3 {
    font-size: 1.8rem !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.colors.dark};
  }

  p {
    font-size: 1.4rem !important;
    line-height: 20px !important;
    font-weight: normal !important;
    color: ${({ theme }) => theme.colors.gray80} !important;
    margin-top: 15px;

    span {
      font-weight: 600 !important;
    }
  }
`

export const TimeResumeContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .section-resume {
    width: 100%;

    .title-resume {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      font-size: 16px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.secondary};
      background-color: ${({ theme }) => theme.colors.secondaryLight};
    }
  }

  .exams-and-procedures {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }
`

export const Item = styled.div`
  padding: 15px;

  strong {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.4rem;
    margin: 0;
    color: ${({ theme }) => theme.colors.gray80};

    strong {
      color: ${({ theme }) => theme.colors.gray80};
    }
  }
`

export const cardNutritional = styled.div`
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.colors.gray10};

  > strong {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.gray80};
  }

  > ul {
    margin: 15px;
  }

  pre {
    padding: 20px;
  }
`
export const HeaderTextEdit = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const ContentPagination = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: flex-end;
  flex-direction: column;
`
