import React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import './styles.scss'

interface LoadingProps {
  title?: string
}

const Loading: React.FunctionComponent<LoadingProps> = ({ title }) => {
  return (
    <div className="container-loading">
      <ThreeDots
        visible={true}
        height="100"
        width="100"
        color="#ff3f72"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <h1>{title}</h1>
    </div>
  )
}

export default Loading
