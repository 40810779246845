import React from 'react'

import { translate } from 'locales'
import { Button } from 'components'
import { parseISO, differenceInMinutes } from 'date-fns'
import CardPsychologicalGuidance from 'components/CardPsychologicalGuidance'
import * as S from './styles'

const defaultMessage = translate('medicalRecord.uninformed')

const TimeLineResumePsicology = ({ history, setLoading }: any) => {
  const doctorTag = history?.scheduling?.tags_doctor
  const patientTag = history?.scheduling?.tags_patient

  const startConsultation = parseISO(history?.scheduling?.from_date_time)
  const endConsultation = parseISO(history?.scheduling?.to_date_time)

  const consultationDuration = differenceInMinutes(
    endConsultation,
    startConsultation
  )

  return (
    <S.TimeResumeContent>
      <section className="section-resume">
        <div className="title-resume">
          {translate('medicalRecord.anotations')}
        </div>
        <div className="content-resume">
          <S.Item>
            <strong>{translate('medicalRecord.reason')}</strong>
            <p>{history?.scheduling?.symptoms || defaultMessage}</p>
          </S.Item>
          <S.Item>
            <strong>{translate('medicalRecord.consultationTime')}</strong>
            <p>{consultationDuration} min</p>
          </S.Item>
          <S.Item>
            <strong>{translate('medicalRecord.patientComplaint')}</strong>
            <div className="tag-container">
              {patientTag?.length > 0 ? (
                <>
                  {patientTag.map((tag: any) => (
                    <Button
                      className="tag-button"
                      key={tag.id}
                      color="tag-active"
                      type="button"
                    >
                      {tag.title}
                    </Button>
                  ))}
                </>
              ) : (
                <p>{defaultMessage}</p>
              )}
            </div>
          </S.Item>
          <S.Item>
            <strong>{translate('medicalRecord.perceivedDemand')}</strong>
            <div className="tag-container">
              {doctorTag?.length > 0 ? (
                <>
                  {doctorTag.map((tag: any) => (
                    <Button
                      className="tag-button"
                      key={tag.id}
                      color="tag-active"
                      type="button"
                    >
                      {tag.title || defaultMessage}
                    </Button>
                  ))}
                </>
              ) : (
                <p>{defaultMessage}</p>
              )}
            </div>
          </S.Item>

          <S.Item>
            <strong>{`${translate('medicalRecord.anotations')}:`}</strong>
            <p>{history?.annotation || defaultMessage}</p>
          </S.Item>

          <S.Item>
            <strong>{translate('medicalRecord.psychologicalGuidance')}</strong>
            <CardPsychologicalGuidance
              patientId={history.patient_id}
              scheduleUUID={history?.scheduling?.id}
              attachment={history?.scheduling?.attachment}
              setLoading={setLoading}
            />
          </S.Item>
        </div>
      </section>
    </S.TimeResumeContent>
  )
}

export default TimeLineResumePsicology
