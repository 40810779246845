import React from 'react'
import moment from 'moment'

import { translate } from 'locales'

// Components
import { Title } from 'components'

// Images
import pdfIcon from 'assets/icons/pdf.svg'
import noData from 'assets/images/no-data.svg'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

const ImagesAndAttachments: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  exams,
}) => {
  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <div className="subsection">
        <Title text={translate('medicalRecord.images')} />

        {exams && exams === null && (
          <S.NoData>
            <img
              src={noData}
              alt="Icone simbolizando que não existe imagens a serem exibidas"
            />
            <span>{translate('medicalRecord.notSendExams')}</span>
          </S.NoData>
        )}

        <S.AttachmentsList>
          {exams &&
            exams !== null &&
            exams?.map((exam: any, index: any) => {
              const isImage = exam.mimetype

              return isImage !== '.pdf' ? (
                <button
                  key={exam.file_key}
                  type="button"
                  className="images"
                  onClick={() => window.open(exam.file_url, '_blank')}
                >
                  <img src={exam.file_url} alt="Imagem de um exame" />
                  <span>
                    {`${translate('medicalRecord.file')} ${index + 1}`}
                  </span>
                </button>
              ) : (
                <button
                  key={exam.file_key}
                  type="button"
                  className="files"
                  onClick={() => window.open(exam.file_url, '_blank')}
                >
                  <img
                    src={pdfIcon}
                    alt="Icone que representa um arquivo PDF"
                  />
                  <span>
                    {`${translate('medicalRecord.file')} ${index + 1}`}
                  </span>
                </button>
              )
            })}
        </S.AttachmentsList>
      </div>
    </S.SectionContent>
  )
}

export default ImagesAndAttachments
