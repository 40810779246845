import React from 'react'
import api from 'configs/api'

export const findPatients = async (
  setPatients: React.Dispatch<React.SetStateAction<Array<any>>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setMaxPages: React.Dispatch<React.SetStateAction<number>>,
  page: number,
  limit: number,
  name: string,
  start = '',
  end = ''
) => {
  setLoading(true)
  try {
    const dataStorage: any = window.localStorage.getItem('@StarBem:user')
    const dataParse = JSON.parse(dataStorage)
    const find = await api.get(
      `/manager/doctors/${dataParse.id}/patients?page=${page}&take=${limit}&user_name=${name}&start=${start}&end=${end}`
    )

    setMaxPages(Math.ceil(find.data.totalUsers / limit || 1))
    setPatients(find.data.patients)
    setLoading(false)
  } catch (err) {
    console.error(err)
    setLoading(false)
  }
}
