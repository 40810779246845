import { useState } from 'react'
import AgoraRTC, {
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
} from 'agora-rtc-sdk-ng'
import VirtualBackgroundExtension from 'agora-extension-virtual-background'
import agoraClient from 'configs/agoraClient'

type DeviceList = {
  cameras: MediaDeviceInfo[]
  microphones: MediaDeviceInfo[]
}

const useVideocall = () => {
  const [processor, setProcessor] = useState<any>(null)
  const [isLocalMain, setIsLocalMain] = useState(false)
  const [localPaused, setLocalPaused] = useState(false)
  const [showConfig, setShowConfig] = useState(false)
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [showDeviceConfig, setShowDeviceConfig] = useState(false)
  const [selectedBg, setSelectedBg] = useState<string>('none')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [remotePaused, setRemotePaused] = useState(false)
  const [remoteDisconnected, setRemoteDisconnected] = useState(true)
  const [devices, setDevices] = useState<DeviceList>({
    cameras: [],
    microphones: [],
  })
  const [selectedCamera, setSelectedCamera] = useState<string>('')
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>('')
  const [localParameters, setLocalParameters] = useState<{
    audioTrack: IMicrophoneAudioTrack | null
    videoTrack: ICameraVideoTrack | null
    audioMuted: boolean
    videoEnabled: boolean
  }>({
    audioTrack: null,
    videoTrack: null,
    audioMuted: false,
    videoEnabled: true,
  })

  const extension = new VirtualBackgroundExtension()
  AgoraRTC.registerExtensions([extension])

  const initAgora = async () => {
    if (agoraClient.connectionState === 'CONNECTED') {
      const users = agoraClient.remoteUsers

      if (users.length) {
        setRemoteDisconnected(false)
        const user = users[0]
        await agoraClient.subscribe(user, 'video')

        const remoteVideoTrack = user.videoTrack

        const remote = document.getElementById('remote')
        if (remoteVideoTrack && remote) {
          remoteVideoTrack.play(remote)
          setRemotePaused(false)
        }

        return
      }
    }

    agoraClient.on('user-published', async (user, mediaType) => {
      setRemoteDisconnected(false)
      await agoraClient.subscribe(user, mediaType)

      const remoteAudioTrack = user.audioTrack
      const remoteVideoTrack = user.videoTrack

      const remote = document.getElementById('remote')
      if (remoteVideoTrack && remote) {
        remoteVideoTrack.play(remote)
        setRemotePaused(false)

        remoteVideoTrack.on('first-frame-decoded', () => {
          setRemotePaused(false)
        })
      }

      if (remoteAudioTrack) {
        remoteAudioTrack.play()
      }
    })

    agoraClient.on('user-unpublished', (user, mediaType) => {
      if (mediaType === 'video') {
        setRemotePaused(true)
      }
    })

    agoraClient.on('user-left', () => {
      setRemoteDisconnected(true)
      setRemotePaused(false)
    })
  }

  const join = async (schedulingId: string, token: string) => {
    if (!agoraClient) return

    try {
      if (
        process.env.REACT_APP_AGORA_IO_APP_ID &&
        agoraClient.connectionState === 'DISCONNECTED'
      ) {
        await agoraClient.join(
          process.env.REACT_APP_AGORA_IO_APP_ID,
          schedulingId,
          token,
          2
        )
      }

      const [localAudioTrack, localVideoTrack] =
        await AgoraRTC.createMicrophoneAndCameraTracks()

      const local = document.getElementById('local')
      setLocalParameters({
        audioTrack: localAudioTrack,
        videoTrack: localVideoTrack,
        audioMuted: false,
        videoEnabled: true,
      })

      if (local) {
        localVideoTrack.play(local)
      }

      await agoraClient.publish([localAudioTrack, localVideoTrack])

      const bgProcessor = extension.createProcessor()
      await bgProcessor.init()
      localVideoTrack
        .pipe(bgProcessor)
        .pipe(localVideoTrack.processorDestination)
      setProcessor(bgProcessor)
    } catch (e) {
      console.log(e)
    }
  }

  const leaveRoom = async () => {
    if (agoraClient) {
      if (localParameters) {
        localParameters?.audioTrack?.close()
        localParameters?.videoTrack?.close()
      }

      await agoraClient.leave()

      localStorage.removeItem('callToken')
      localStorage.removeItem('callAppointmentId')
    }
  }

  const getDevices = async () => {
    const allDevices = await AgoraRTC.getDevices()
    const cameras = allDevices.filter((device) => device.kind === 'videoinput')
    const microphones = allDevices.filter(
      (device) => device.kind === 'audioinput'
    )
    setDevices({ cameras, microphones })
    setSelectedCamera(cameras[0]?.deviceId || '')
    setSelectedMicrophone(microphones[0]?.deviceId || '')
  }

  const getProcessorInstance = async () => {
    if (!processor && localParameters.videoTrack) {
      const newProcessor = extension.createProcessor()
      try {
        await newProcessor.init()
        localParameters.videoTrack
          .pipe(newProcessor)
          .pipe(localParameters.videoTrack.processorDestination)
        setProcessor(newProcessor)
        return newProcessor
      } catch (error) {
        console.error(error)
        return null
      }
    }
    return processor
  }

  const setBackgroundNone = async () => {
    const proc = await getProcessorInstance()
    if (proc) {
      await proc.disable()
      setSelectedBg('none')
    }
  }

  const setBackgroundBlurring = async () => {
    const proc = await getProcessorInstance()
    if (proc) {
      proc.setOptions({ type: 'blur', blurDegree: 2 })
      await proc.enable()
      setSelectedBg('blur')
    }
  }

  const setBackgroundImage = async (imageUrl: string) => {
    const imgElement = document.createElement('img')
    imgElement.onload = async () => {
      const proc = await getProcessorInstance()
      if (proc) {
        const options = { type: 'img', source: imgElement }
        try {
          await proc.setOptions(options)
          await proc.enable()
          setSelectedBg(imageUrl)
        } catch (e) {
          console.error('Erro ao definir imagem de fundo:', e)
        }
      }
    }
    imgElement.src = imageUrl
  }

  const toggleAudio = () => {
    const audioMuted = !localParameters.audioMuted
    localParameters?.audioTrack?.setMuted(audioMuted)
    setLocalParameters({ ...localParameters, audioMuted })
  }

  const toggleVideo = () => {
    const videoEnabled = !localParameters.videoEnabled
    localParameters?.videoTrack?.setEnabled(videoEnabled)
    setLocalPaused(!videoEnabled)
    setLocalParameters({ ...localParameters, videoEnabled })
  }

  const handleVideoClick = () => {
    setIsLocalMain(!isLocalMain)
  }

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen)
  }

  const handleChangeCamera = async (deviceId: string) => {
    if (agoraClient && localParameters.videoTrack) {
      const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
        cameraId: deviceId,
      })
      await agoraClient.unpublish(localParameters.videoTrack)
      localParameters.videoTrack.close()
      setLocalParameters((prev) => ({
        ...prev,
        videoTrack: newVideoTrack,
      }))
      const local = document.getElementById('local')
      if (local) {
        newVideoTrack.play(local)
      }
      await agoraClient.publish(newVideoTrack)
    }
  }

  const handleChangeMicrophone = async (deviceId: string) => {
    if (agoraClient && localParameters.audioTrack) {
      const newAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        microphoneId: deviceId,
      })
      await agoraClient.unpublish(localParameters.audioTrack)
      localParameters.audioTrack.close()
      setLocalParameters((prev) => ({
        ...prev,
        audioTrack: newAudioTrack,
      }))
      await agoraClient.publish(newAudioTrack)
      setSelectedMicrophone(deviceId)
    }
  }

  const handleConfigClick = () => {
    setShowConfig(!showConfig)
    setShowDeviceConfig(false)
    setButtonsDisabled(!buttonsDisabled)
  }

  const handleDeviceConfigClick = () => {
    setShowDeviceConfig(!showDeviceConfig)
    setShowConfig(false)
    setButtonsDisabled(!buttonsDisabled)
  }

  return {
    leaveRoom,
    processor,
    remotePaused,
    remoteDisconnected,
    initAgora,
    join,
    extension,
    localParameters,
    setLocalParameters,
    setProcessor,
    getDevices,
    devices,
    selectedCamera,
    selectedMicrophone,
    handleChangeCamera,
    handleDeviceConfigClick,
    handleConfigClick,
    handleChangeMicrophone,
    toggleFullScreen,
    handleVideoClick,
    toggleAudio,
    toggleVideo,
    setBackgroundBlurring,
    setBackgroundNone,
    setBackgroundImage,
    selectedBg,
    localPaused,
    isFullScreen,
    isLocalMain,
    showConfig,
    setShowConfig,
    showDeviceConfig,
    setShowDeviceConfig,
    buttonsDisabled,
    setButtonsDisabled,
  }
}

export default useVideocall
