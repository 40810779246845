// eslint-disable
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { normalizeName } from 'utils'
import moment from 'moment'
import {
  formatDistanceToNow,
  addMinutes,
  subHours,
  differenceInMilliseconds,
  parseISO,
  differenceInHours,
} from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { telephoneMask } from 'js-essentials-functions'
import useStorage from 'hooks/storageSync'

import { translate } from 'locales'

import { calcIMC } from 'utils/convert'

// Utils
import * as Global from 'global/styles.global'
import {
  Header,
  Card,
  Button,
  Loading,
  ModalShow,
  VideoCallSdk,
} from 'components'
import whatsappIcon from 'assets/icons/whatsapp.svg'
import videoIcon from 'assets/icons/video.svg'
import getAge from 'utils/getAge'
import useFetch, { useSWRConfig } from 'hooks/useFetch'
import StartService from 'components/Modal/StartService'
import PublicIcon from '@mui/icons-material/Public'
import ChatPatient from '../New/ChatPatient'
import { menuItems } from './utils'

import { IAccompaniment } from '../New/interfaces'

// Styles
import * as S from './styles'

// Components
import Resume from './Resume'
import Analysis from './Analysis'
import AnamneseNutri from './AnamneseNutri'
import History from './History'
import TermsPsico from './TermsPsico'
import NutritionalGuidance from './NutritionalGuidance'
import PsychologicalGuidance from './PsychologicalGuidance'

// Services
import {
  // findSchedule,
  initAndCancelSchedule,
  initVideoCall,
  sendCallPush,
} from '../New/services'

import { saveMedicalRecord, createAccompaniment } from './services'

import ImagesAndAttachments from './ImagesAndAttachments'

// Icons

const NewMedicalRecord = () => {
  const topContentRef = useRef<HTMLDivElement>(null)
  const params: any = useParams()
  const history = useHistory()
  const { mutate } = useSWRConfig()

  const [storage]: any = useStorage(params.scheduleUUID, '')

  const defaultAccompaniment: IAccompaniment = {
    state: 'sim',
    reason: '',
    day: '',
  }

  // State
  const [cancelDescription, setCancelDescription] = useState('')
  const [showCancelModal, setShowCancelModal] = useState('none')
  const [showFinishModal, setShowFinishModal] = useState('none')
  const [showInitModal, setShowInitModal] = useState('none')
  const [selectedMenu, setSelectedMenu] = useState(1)
  const [loading, setLoading] = useState(false)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [state, setState] = useState({
    showModal: false,
    message: '',
    url: '',
  })
  const [token, setToken] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null)

  const [accompaniment, setAccompaniment] =
    useState<IAccompaniment>(defaultAccompaniment)

  const { data: schedule, isLoading } = useFetch({
    url: `schedulings/v1/${params?.scheduleUUID}/doctor`,
  })

  const isBr = schedule?.patient?.address?.country === 'BR'

  const verifySavedCallToken = async () => {
    const callToken = localStorage.getItem('callToken')
    const callAppointmentId = localStorage.getItem('callAppointmentId')

    if (callToken && callAppointmentId === params.scheduleUUID) {
      setToken(callToken)
    }
  }

  useEffect(() => {
    const handleSize = () => {
      setWindowHeight(window.innerHeight)
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleSize)

    return () => {
      window.removeEventListener('resize', handleSize)
    }
  }, [])

  useEffect(() => {
    verifySavedCallToken()
  }, [])

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = subHours(new Date(), 3)
      const start = parseISO(schedule?.from_date_time)
      const difference = differenceInMilliseconds(start, now) - 3 * 60 * 1000

      if (difference <= 0) {
        setIsButtonDisabled(false)
        setTimeRemaining(0)
      } else {
        setTimeRemaining(difference)
        if (difference <= 0) {
          setIsButtonDisabled(false)
        } else {
          setIsButtonDisabled(true)
        }
      }
    }

    calculateTimeRemaining()

    const intervalId = setInterval(() => {
      calculateTimeRemaining()
    }, 1000)

    return () => clearInterval(intervalId)
  }, [schedule?.from_date_time])

  const formatTime = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    const remaining = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`

    if (milliseconds <= 0) {
      return {
        duration: 'menos de 3 minutos',
        remaining,
      }
    }

    const duration = formatDistanceToNow(
      addMinutes(new Date(), milliseconds / 60000),
      { includeSeconds: false, locale: ptBR }
    )

    return {
      duration,
      remaining,
    }
  }

  const hideModal = () => {
    // @ts-ignore
    setShowCancelModal('none')
  }

  const hideFinishModal = () => {
    setShowFinishModal('none')
  }
  const hideInitModal = () => {
    setShowInitModal('none')
  }
  const handleUpdateData = () => {
    mutate(`schedulings/v1/${params?.scheduleUUID}/doctor`)
  }
  const handleInitService = useCallback(() => {
    initAndCancelSchedule({
      scheduleUUID: schedule?.id,
      data: {
        status: 'started',
      },
      setSchedule: null,
      setLoading,
      history,
      action: handleUpdateData,
    })
  }, [history, schedule])
  const handleCancelSchedule = useCallback(() => {
    initAndCancelSchedule({
      scheduleUUID: schedule?.id,
      data: {
        status: 'canceled',
        cancel_description: cancelDescription,
      },
      setSchedule: null,
      setLoading,
      history,
      action: handleUpdateData,
    })
  }, [history, schedule, cancelDescription])

  const handleOpenWhatsApp = () => {
    window.open(
      `https://wa.me/${isBr ? '55' : '1'}${schedule?.cellphone}`,
      '_blank'
    )
  }

  const handleSaveMedicalRecord = async () => {
    setShowFinishModal('none')

    const data = {
      user_uuid: schedule?.patient?.id,
      schedule_uuid: schedule?.id,
      specialty: schedule?.specialty_id,
      ...storage?.analysis,
      ...storage?.tags,
      nutritional_anamnese: storage?.nutritional_anamnese,
    }

    if (
      accompaniment.state === 'sim' &&
      (schedule?.specialty_name?.toLowerCase() === 'psicólogo' ||
        schedule?.specialty_name?.toLowerCase() === 'nutricionista')
    ) {
      const datas = {
        scheduling_id: schedule?.id,
        scheduling_date: schedule?.from_date_time,
        doctor_id: schedule?.doctor?.id,
        doctor_name: schedule?.doctor?.name,
        specialty_id: schedule?.specialty_id,
        specialty_name: schedule?.specialty_name,
        patient_id: schedule?.patient?.id,
        patient_name: schedule?.patient?.name,
        patient_cellphone: schedule?.cellphone,
        reason_accompaniment: accompaniment.reason,
        date_accompaniment: `${accompaniment.day} 00:00:00`,
      }
      const res = await createAccompaniment(datas)
      if (!res) {
        return
      }
    }
    saveMedicalRecord(
      schedule?.patient?.id,
      schedule?.id,
      data,
      history,
      setLoading
    )
  }
  const handleScrollToTop = () => {
    if (topContentRef.current) {
      setTimeout(() => {
        topContentRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }

  const userSusVideo =
    schedule?.clinic?.attendence === 'aplication' &&
    !schedule?.plan_associate_id &&
    !schedule?.product_purchased_id

  const doctorName = schedule?.doctor?.name
  const doctorGender = schedule?.doctor?.gender
  const doctorNameShow =
    doctorGender === 'male' ? `Dr. ${doctorName}` : `Dra. ${doctorName}`

  const verifyScrren: any = window.screen.width < 600

  const handleClickTime = () => {
    const storedTime = localStorage.getItem(`clickTime${schedule?.id}`)

    if (!storedTime) {
      const currentTime = new Date().toISOString()
      localStorage.setItem(`clickTime${schedule?.id}`, currentTime)
    }
  }

  const currentTime = localStorage.getItem(`clickTime${schedule?.id}`)

  const CancelInMinutes = currentTime
    ? Math.abs(moment(currentTime).diff(moment(), 'minutes'))
    : 0

  const now = new Date()
  const start = parseISO(schedule?.starts_at)
  const startDate = differenceInHours(now, start)

  const datasFinish = !currentTime && startDate > 5 ? true : false
  return (
    <S.Container>
      <div ref={topContentRef} />
      {(isLoading || loading) && (
        <Loading
          title={state.message || translate('medicalRecord.loadingData')}
        />
      )}
      <Header openNewTab={!!token} />
      <S.Content>
        <S.LeftContainer
          windowHeight={windowHeight}
          windowWidth={windowWidth}
          isCenter={!!token}
        >
          <S.GridContent container xl={12}>
            {(userSusVideo || schedule?.type_attendance === 'video') && (
              <Grid
                item
                xs={12}
                md={12}
                style={{ paddingLeft: 16, paddingRight: 16 }}
              >
                <S.MessageContainer>
                  <S.MessageText>
                    <b>🚨 Atenção: </b> Durante a videochamada com o paciente, é
                    essencial permanecer na tela para garantir uma comunicação
                    contínua e sem interrupções. Sair da tela pode causar
                    problemas na transmissão de vídeo e áudio.
                  </S.MessageText>
                  <S.MessageText>
                    🚨 <b>Observação: </b> Enquanto a consulta por vídeo estiver
                    acontecendo não será possível finalizar ou cancelar o
                    atendimento!
                  </S.MessageText>
                  <S.MessageText>
                    <b>
                      🌟 Por favor, permaneça na tela enquanto a chamada estiver
                      ativa para oferecer a melhor experiência de atendimento ao
                      seu paciente. 🌟
                    </b>
                  </S.MessageText>
                </S.MessageContainer>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={3}
              style={{
                padding: '2px 6px',
                borderRightWidth: 1,
                borderRightColor: '#f0f2f8',
                borderRightStyle: 'solid',
                order: verifyScrren ? 2 : 1,
              }}
            >
              <Card styles={{ padding: 0 }}>
                <S.TopContent>
                  <div className="text-content">
                    <span>{translate('medicalRecord.attendance')}</span>
                    <p>
                      {moment
                        .utc(schedule?.from_date_time)
                        .format('DD/MM/YYYY HH:mm')}
                    </p>
                  </div>
                  <div className="text-content">
                    <span>{translate('medicalRecord.time')}</span>
                    <p style={{ color: 'red' }}>
                      {`${schedule?.quantity_slot === 2 ? 60 : 30} ${translate(
                        'medicalRecord.minutes'
                      )}`}
                    </p>
                  </div>
                  {/* {isBr && ( */}
                  <div className="text-content">
                    <span>WhatsApp</span>
                    <p>
                      {`${
                        isBr
                          ? telephoneMask(schedule?.cellphone || '')
                          : `+1 ${schedule?.cellphone}`
                      }`}
                    </p>
                  </div>
                  {/* )} */}
                  <div className="button-content">
                    {timeRemaining !== null && timeRemaining > 0 && (
                      <S.MessageContainer>
                        <S.MessageText>
                          🚨 Por favor, aguarde. O atendimento só poderá ser
                          iniciado 3 minutos antes do horário da consulta, sendo
                          assim você poderá iniciar a consulta em{' '}
                          <b>{formatTime(timeRemaining).duration}</b>.
                        </S.MessageText>
                        <S.MessageText>
                          <b>Tempo restante:</b>{' '}
                          {formatTime(timeRemaining).remaining}
                        </S.MessageText>
                      </S.MessageContainer>
                    )}
                    {schedule?.status === 'pending' && (
                      <Button
                        color="primary"
                        type="button"
                        size="normal"
                        styles={{ width: '100%', marginBottom: 10 }}
                        onClick={() => setShowInitModal('block')}
                        disabled={isButtonDisabled}
                      >
                        {translate('medicalRecord.initAttendance')}
                      </Button>
                    )}

                    {schedule?.status === 'started' && (
                      <>
                        <Button
                          color="primary"
                          type="button"
                          size="normal"
                          styles={{ width: '100%', marginBottom: 10 }}
                          onClick={() => setShowFinishModal('block')}
                          disabled={
                            !!token || (CancelInMinutes < 5 && !datasFinish)
                          }
                          title={
                            token
                              ? 'O atendimento não pode ser finalizado enquanto a vídeo chamada estiver acontecendo!'
                              : 'Finalizar o atendimento'
                          }
                        >
                          {translate('medicalRecord.finishAttendance')}
                        </Button>

                        <Button
                          color="danger"
                          type="button"
                          size="normal"
                          styles={{ width: '100%' }}
                          disabled={
                            schedule?.status !== 'started' ||
                            !!token ||
                            (CancelInMinutes < 5 && !datasFinish)
                          }
                          title={
                            token
                              ? 'O atendimento não pode ser cancelado enquanto a vídeo chamada estiver acontecendo!'
                              : 'Cancelar o atendimento'
                          }
                          onClick={() => {
                            setState({ ...state, message: '' })
                            setShowCancelModal('block')
                          }}
                        >
                          {translate('medicalRecord.cancelAppointment')}
                        </Button>

                        <Button
                          color="success"
                          type="button"
                          size="normal"
                          icon={whatsappIcon}
                          iconStyles={{ width: 20, marginRight: 15 }}
                          styles={{ width: '100%', marginTop: 10 }}
                          onClick={() => {
                            handleOpenWhatsApp()
                            handleClickTime()
                          }}
                          disabled={schedule?.status === 'pending'}
                          title="Iniciar chat via WhatsApp"
                        >
                          {translate('medicalRecord.initChat')}
                        </Button>
                      </>
                    )}

                    {schedule?.status === 'started' &&
                      (userSusVideo ||
                        schedule?.type_attendance === 'video') && (
                        <Button
                          color="success"
                          type="button"
                          size="normal"
                          icon={videoIcon}
                          iconStyles={{ width: 18, marginRight: 15 }}
                          styles={{ width: '100%', marginTop: 10 }}
                          title={
                            token
                              ? 'A vídeo chamada está em andamento!'
                              : 'Iniciar video chamada interna com o paciente'
                          }
                          onClick={() => {
                            handleScrollToTop()
                            handleClickTime()
                            sendCallPush(schedule.id)
                            initVideoCall(
                              schedule?.id,
                              state,
                              setLoading,
                              setState,
                              setToken
                            )
                          }}
                          disabled={schedule?.status === 'pending' || !!token}
                        >
                          {translate('medicalRecord.initVideo')}
                        </Button>
                      )}
                  </div>
                </S.TopContent>
                <S.Menu>
                  <ul>
                    {menuItems.map((item: any, key: number) => {
                      if (schedule) {
                        return item.by.includes(
                          schedule?.specialty_name?.toLowerCase()
                        ) ? (
                          <S.MenuList
                            key={item.id}
                            className={key === selectedMenu ? 'active' : ''}
                          >
                            <button
                              type="button"
                              onClick={() => setSelectedMenu(key)}
                            >
                              {item.title}
                            </button>
                          </S.MenuList>
                        ) : (
                          <></>
                        )
                      }
                    })}
                  </ul>
                </S.Menu>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              md={token ? 5 : 9}
              style={{ padding: '2px 6px', order: verifyScrren ? 3 : 2 }}
            >
              <Card>
                <S.PatientContent>
                  {schedule?.patient?.address?.country === 'BR' ? (
                    <h3>{normalizeName(schedule?.patient?.name || '')}</h3>
                  ) : (
                    <div className="d-flex align-items-center">
                      <h3
                        style={{
                          borderRight: '2px solid #000',
                          paddingRight: '10px',
                        }}
                      >
                        {schedule?.patient?.name || ''}
                      </h3>
                      <div
                        className="d-flex align-items-center"
                        style={{ paddingLeft: '10px', gap: '5px' }}
                      >
                        <PublicIcon fontSize="large" />
                        <span>Paciente Global</span>
                      </div>
                    </div>
                  )}
                  <p>
                    <span>{translate('medicalRecord.age')}</span>
                    {`${getAge(
                      schedule?.patient?.personal_data?.birthdate || ''
                    )} anos`}
                    <br />
                    <span>{translate('medicalRecord.bmi')}</span>
                    {calcIMC(
                      schedule?.patient?.medical_record?.height,
                      schedule?.patient?.medical_record?.weight
                    )
                      ? `${calcIMC(
                          schedule?.patient?.medical_record?.height,
                          schedule?.patient?.medical_record?.weight
                        )} kg/m²`
                      : translate('medicalRecord.unavailable')}
                    <br />
                    <span>{translate('medicalRecord.gender')}</span>
                    {schedule?.patient?.personal_data?.gender === 'male' &&
                      translate('medicalRecord.male')}
                    {schedule?.patient?.personal_data?.gender === 'female' &&
                      translate('medicalRecord.female')}
                    {schedule?.patient?.personal_data?.gender === 'other' &&
                      translate('medicalRecord.other')}

                    {schedule?.patient?.personal_data?.gender === null &&
                      translate('medicalRecord.uninformed')}
                    <br />
                    <span>{translate('medicalRecord.birthdate')}</span>
                    {moment(schedule?.patient?.personal_data?.birthdate).format(
                      'DD/MM/YYYY'
                    )}
                    <br />
                    <span>{translate('medicalRecord.weight')}</span>
                    {`${schedule?.patient?.medical_record?.weight || ''} kg`}
                    <br />
                    <span>{translate('medicalRecord.height')}</span>
                    {`${schedule?.patient?.medical_record?.height || ''} cm`}
                    <br />

                    {schedule?.patient?.company?.name && (
                      <>
                        <span>{translate('medicalRecord.company')}</span>
                        {schedule?.patient?.company?.name}
                        <br />
                      </>
                    )}
                  </p>
                  <Global.Line />
                </S.PatientContent>
                {selectedMenu === 0 && (
                  <ChatPatient
                    doctorName={doctorNameShow}
                    scheduleData={schedule}
                    scheduleUUID={schedule?.id}
                  />
                )}
                {selectedMenu === 1 && (
                  <Resume
                    doctorName={doctorNameShow}
                    scheduleUUID={schedule?.id}
                    scheduleData={schedule}
                  />
                )}
                {selectedMenu === 2 && (
                  <Analysis
                    doctorName={doctorNameShow}
                    scheduleUUID={schedule?.id}
                    scheduleData={schedule}
                  />
                )}
                {selectedMenu === 3 && (
                  <AnamneseNutri
                    doctorName={doctorNameShow}
                    scheduleUUID={schedule?.id}
                    scheduleData={schedule}
                  />
                )}
                {selectedMenu === 4 && (
                  <History
                    doctorName={doctorNameShow}
                    scheduleUUID={schedule?.id}
                    schedule={schedule}
                    specialty={schedule.specialty_id}
                  />
                )}
                {selectedMenu === 5 && (
                  <>
                    <ImagesAndAttachments
                      doctorName={doctorNameShow}
                      scheduleUUID={schedule?.id}
                    />
                  </>
                )}
                {selectedMenu === 6 && (
                  <>
                    <TermsPsico
                      autorization={schedule.authorization}
                      age={getAge(
                        schedule?.patient?.personal_data?.birthdate || ''
                      )}
                    />
                  </>
                )}
                {selectedMenu === 7 && (
                  <>
                    <NutritionalGuidance
                      doctorName={doctorNameShow}
                      patientId={schedule?.patient?.id}
                      scheduleUUID={schedule?.id}
                      nutritional={schedule?.nutritional?.attachment || false}
                      setLoading={setLoading}
                      recipe={schedule?.nutritional?.recipe || false}
                    />
                  </>
                )}
                {selectedMenu === 8 && (
                  <>
                    <PsychologicalGuidance
                      doctorName={doctorNameShow}
                      patientId={schedule?.patient?.id}
                      scheduleUUID={schedule?.id}
                      attachment={schedule?.attachment || false}
                      setLoading={setLoading}
                    />
                  </>
                )}
              </Card>
            </Grid>
          </S.GridContent>
        </S.LeftContainer>
        {token && (
          <S.RightContainer>
            <VideoCallSdk
              onCloseRoom={() => setToken('')}
              token={token}
              schedulingId={schedule?.id}
              windowHeight={windowHeight}
              windowWidth={windowWidth}
            />
          </S.RightContainer>
        )}
      </S.Content>
      <>
        <div className="overlay" style={{ display: showCancelModal }} />
        <div className="modal-wrapper" style={{ display: showCancelModal }}>
          <div className="info-container">
            <ModalShow
              closeModal={hideModal}
              setDescription={setCancelDescription}
              description={cancelDescription}
              handleConfirm={handleCancelSchedule}
            />
          </div>
        </div>
      </>
      <>
        <div className="overlay" style={{ display: showFinishModal }} />
        <div className="modal-wrapper" style={{ display: showFinishModal }}>
          <div className="info-container">
            {(schedule?.specialty_name?.toLowerCase() === 'psicólogo' ||
              schedule?.specialty_name?.toLowerCase() === 'nutricionista') && (
              <ModalShow
                closeModal={hideFinishModal}
                cancelModal={false}
                handleConfirm={handleSaveMedicalRecord}
                setAccompaniment={setAccompaniment}
                accompaniment={accompaniment}
              />
            )}
          </div>
        </div>
        <div className="overlay" style={{ display: showInitModal }} />
        <div className="modal-wrapper" style={{ display: showInitModal }}>
          <div className="info-container">
            <StartService
              closeModal={hideInitModal}
              handleConfirm={handleInitService}
            />
          </div>
        </div>
      </>
    </S.Container>
  )
}

export default NewMedicalRecord
