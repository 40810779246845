import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { Pagination } from '@material-ui/lab'
import { translate } from 'locales'

// Styles
import { Title, Loading, CardDate } from 'components'
import noData from 'assets/images/no-data.svg'
import * as S from './styles'

import TimeLineResume from './TimeLineResume'

// Interfaces
import { IComponentProps } from './interfaces'

import { findAllUserMedicalRecord } from './services'

moment.locale('pt-br', {
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
})

const useStyles = makeStyles((theme: any) => ({
  timeline: {
    padding: 0,
  },
  left: {
    flex: 'none',
    paddingLeft: 0,
  },
  right: {
    paddingRight: 0,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const History: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  schedule,
}) => {
  const classes = useStyles()

  // States
  const [timeline, setTimeline] = useState<Array<any>>([])
  const [loading, setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  useEffect(() => {
    let isActive = true

    findAllUserMedicalRecord(
      schedule?.patient,
      isActive,
      setTimeline,
      setLoading,
      page,
      setTotalPages
    )

    return () => {
      isActive = false
    }
    // eslint-disable-next-line
  }, [page])

  return (
    <S.SectionContent>
      {loading && (
        <Loading title={translate('medicalRecord.loadingHistoric')} />
      )}

      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <div className="subsection">
        <Title text={translate('medicalRecord.historicPatiente')} />
        <div className="subsection-item">
          <Timeline align="left" className={classes.timeline}>
            {timeline.length === 0 && (
              <S.NoData>
                <img
                  src={noData}
                  alt="Icone simbolizando que não existe histórico do paciente"
                />
                <span>{translate('medicalRecord.notMedicalRecord')}</span>
              </S.NoData>
            )}

            {timeline &&
              timeline.map((record: any) => (
                <TimelineItem key={record.id}>
                  <TimelineOppositeContent className={classes.left}>
                    <CardDate
                      day={moment.utc(record.created_at).format('DD')}
                      month={moment.utc(record.created_at).format('MMM')}
                      year={moment.utc(record.created_at).format('YYYY')}
                    />
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className={classes.right}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={record.id}
                      >
                        <div
                          className={classes.heading}
                          style={{
                            marginTop: 0,
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Typography className={classes.heading}>
                            {`${translate('medicalRecord.by')} Dr(a). ${
                              record?.scheduling?.doctor?.name
                            }`}
                            <Typography>
                              {`${translate('schedule.scheduleDate')}: ${moment(
                                record.scheduling.from_date_time
                              ).format('DD/MM/YYYY')}`}
                            </Typography>
                          </Typography>
                        </div>
                        <div>
                          <Typography>
                            {` (${record?.scheduling?.specialty_name})`}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: 'block' }}>
                        <TimeLineResume
                          doctorName=""
                          scheduleUUID={schedule?.id}
                          record={record}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
          <S.ContentPagination>
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                onChange={(
                  event: React.ChangeEvent<unknown>,
                  value: number
                ) => {
                  setPage(value)
                }}
              />
            )}
          </S.ContentPagination>
        </div>
      </div>
    </S.SectionContent>
  )
}

export default History
