import React, { useEffect, useState } from 'react'

import ReactSpeedometer from 'react-d3-speedometer'
import moment from 'moment'

import { translate } from 'locales'

import { Header, Card, Loading, Button, Title } from 'components'
import * as Global from 'global/styles.global'
import { ChevronLeft, ChevronRight } from '@styled-icons/boxicons-regular'
import * as S from './styles'

import { getCsat } from './services'

const Rating = () => {
  const [month, setMonth] = useState(new Date())
  const [countCsat, setCountCsat] = useState(0)
  const [ratings, setRatings] = useState<any>()
  const [coments, setComents] = useState<any>()
  const [loading, setLoading] = useState(false)

  const dataInStorage: any = window.localStorage.getItem('@StarBem:user')
  const dataPase = JSON.parse(dataInStorage)

  const changeDate = (value: any) => {
    setMonth(value)
    getCsat(
      dataPase.id,
      value,
      setCountCsat,
      setRatings,
      setComents,
      setLoading
    )
  }

  const verifyMonth =
    moment(month).format('MM-YYYY') === moment().format('MM-YYYY')

  useEffect(() => {
    getCsat(
      dataPase.id,
      month,
      setCountCsat,
      setRatings,
      setComents,
      setLoading
    )
  }, [])

  const colors: any = {
    1: 'rgb(106, 215, 45)',
    2: 'rgb(174, 226, 40)',
    3: 'rgb(236, 219, 35)',
    4: 'rgb(246, 150, 30)',
    5: 'rgb(255, 71, 26)',
  }

  return (
    <Global.Container>
      {loading && <Loading title={translate('rating.loading')} />}
      <Header />
      <S.Content>
        <div
          className="container"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h1 className="title-config">
            {translate('rating.evaluatedServices')}
          </h1>
          <S.ContentCharts>
            <div className="header">
              {/* <h3>CSAT</h3> */}
              <div className="buttons">
                <Button
                  color="primary"
                  type="button"
                  size="small"
                  styles={{ marginRight: 15 }}
                  onClick={() => {
                    changeDate(moment(month).subtract(1, 'month'))
                  }}
                >
                  <ChevronLeft size={30} color="#fff" />
                </Button>
                <h4 style={{ whiteSpace: 'nowrap' }} className="mb-0">
                  {moment(month).format('MMM YYYY')}
                </h4>
                <Button
                  color="primary"
                  size="small"
                  styles={{ marginLeft: 15 }}
                  onClick={() => {
                    changeDate(moment(month).add(1, 'month'))
                  }}
                  type="button"
                  disabled={verifyMonth}
                >
                  <ChevronRight size={30} color="#fff" />
                </Button>
              </div>
            </div>
          </S.ContentCharts>
        </div>
        <S.ContentCard
          styles={{ display: 'flex', flexDirection: 'row' }}
          className="pb-0"
        >
          <S.ContentCharts>
            <ReactSpeedometer
              paddingVertical={15}
              valueTextFontSize="30px"
              value={countCsat}
              maxValue={100}
              needleColor="steelblue"
              needleTransitionDuration={1500}
            />
          </S.ContentCharts>
          <S.ContentEmotions>
            {ratings?.map(
              (item: { name: string; number: number; count: string }) => (
                <S.CardEmotions key={item.name} color={colors[item?.number]}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <S.EmotionNumber color={colors[item?.number]}>
                      {item.count}
                    </S.EmotionNumber>
                    <S.EmotionName>{item.name}</S.EmotionName>
                  </div>
                </S.CardEmotions>
              )
            )}
          </S.ContentEmotions>
        </S.ContentCard>
        {coments?.length > 0 && (
          <Card className="pt-0">
            <Title text={translate('rating.comments')} />
            <S.ContentComents>
              {coments?.map((item: { coment: string }) => (
                <S.CardComent key={item.coment}>
                  <S.ComentName>{item.coment}</S.ComentName>
                </S.CardComent>
              ))}
            </S.ContentComents>
          </Card>
        )}
      </S.Content>
    </Global.Container>
  )
}

export default Rating
